import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/next/font/local/target.css?{\"path\":\"domains/fonts/munchkinFont.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":\"./munchkin.woff2\",\"variable\":\"--munchkin-font\"}],\"variableName\":\"munchkinFont\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/packages/site/domains/i18n/I18nProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/packages/site/domains/i18n/LanguageSwitcher.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/packages/site/domains/theme/theme.ts");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/packages/site/domains/theme/ThemeRegistry.tsx");
